import { useEffect, useRef, useState } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import React from "react";
import { FMFilters, ReconciliationTransactionReportModel_Resp, ModelForFilters, ReconciliationTransactionReportModel } from "../models/TaxTransactionReportModel";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps, debounce } from "@mui/material";
import { ReconciliationOpenList } from "../components/Lists/ReconciliationOpenList";
import { writeXlsxFile } from "../helper/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import formatPrice from "../helper/currencyFormatter";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import { FMTextField } from "../components/Elements/input";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ReconciliationClosedList } from "../components/Lists/ReconciliationClosedList";
import { isValid } from "../components/Elements/PolicyStatuses";
import {GridFilterItem, GridFilterModel, GridLinkOperator, GridSortModel } from "@mui/x-data-grid-pro";
import { MenuDropdown } from "../components/Elements/MenuDropdown";
import FilerNotes from "../components/Elements/filerNotes";
import { getPolicyFilingAndReport, NewCompletedFilingsModel } from "../models/NewCompletedFilingsModel";
import { FMTabs } from "./Tabs";

export enum ReconTabs {
    Open = 1,
    Closed = 2,
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{
                height: "100%",
            }}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        height: "100%",
                        // p: 3
                    }}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const ReconciliationQueue = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initValue: ReconciliationTransactionReportModel_Resp = {
        getData_Resp: [],
        totalCount: 0
    }
    const [ReportModule, setReportModule] = useState<ReconciliationTransactionReportModel_Resp>(initValue)
    const [stateId, setstateId] = useState(0);
    const [tax, setTax] = useState("");
    const PloicyNum = React.useRef("");
    const insName = React.useRef("");
    const effectiveDateEd = React.useRef("");
    const effectiveDateSt = React.useRef("");
    const stateData = React.useRef(0);
    const customerData = React.useRef(0);
    const taxData = React.useRef("");
    const statusData = React.useRef("");
    const [customerId, setCustomerId] = useState(0);
    const [statuses, setstatus] = useState("");
    const [selectedRows, setSelectedRows] = useState<ReconciliationTransactionReportModel[] | null>([]);
    const [_currentPage, setCurrentPage] = React.useState(1);
    const [_pageSize, setPageSize] = React.useState(50);
    const sortModelRef = useRef<GridSortModel>([]);
    const [queryOptions, setQueryOptions] = React.useState<FMFilters>();
    const [currentPolicySubStatus, setCurrentPolicySubStatus] = useState<string>('');
    const [openNotesDialog, setOpenNotesDialog] = useState<string>('');
    const [editableSelectedRows, seteditableSelectedRows] = useState<NewCompletedFilingsModel[]>();
    const [openPolicyDueDateNullDialog, setOpenPolicyDueDateNullDialog] = useState(false);
    const [CustPolicyNullDueDateData, SetCustPolicyNullDueDateData] = React.useState<any>();
    const filteredSelectedRows: NewCompletedFilingsModel[] | null = selectedRows
    ?.filter((item): item is NewCompletedFilingsModel => item.customerpolicyid !== undefined) ?? null;

    

    const handleSubmitNotes = (data: { status: string, noteType: string, noteDetail: string, noteUser: string, firstRequestDate: string, secondRequestDate: string, thirdRequestDate: string, suspendedDate: string, noteDate?: string, policySubStatus?: string, dateDeclarationFiled?: string }) => {
        const { firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteType, noteDetail, noteUser, noteDate, status, policySubStatus, dateDeclarationFiled } = data;
        setOpenNotesDialog('');
        updateAllStatus(status, selectedRows, noteDetail, noteUser, firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteDate, policySubStatus, dateDeclarationFiled);
    };
    
    const handleOpenNotesDialog = (status: string) => {
        setOpenNotesDialog(status);
    };
    const isDueDateWithinRange = (dueDateStr: string): boolean => {
        const currentDate = new Date();
        const dueDate = parseDueDate(dueDateStr);
        const dateDifference = dueDate.getTime() - currentDate.getTime();
        const dayDifference = dateDifference / (1000 * 3600 * 24);
        return dayDifference >= 0 && dayDifference <= 9;
    };
    const parseDueDate = (dueDateStr: string): Date => {
        const currentDate = new Date();
        const [month, day] = dueDateStr.split('-').map(Number);
        const year = currentDate.getFullYear();
        return new Date(year, month - 1, day);
    };

    const handleCloseNotesDialog = () => {
        setOpenNotesDialog('');
    };
    const initFilterModel: ModelForFilters = {
        customerpolicyid: 0
      }

    // const keyNames: (keyof ReconciliationTransactionReportModel)[] = [
    //     // 'dueDate',
    //     'client', 'invoiceNumber', 'insuredName', 'policyNumber',
    //     'homeState', 'transactionType', 'invDate', 'taxType',
    //     'policyEffDate', 'policyExpDate', 'grossPremium', 'coverage',
    //     'carrier', 'naic', 'nonAdmittedPremium', 'taxableFees', 'nonTaxableFees',
    //     'taxBalance', 'clientDiv', 'dateInvoiceAdded', 'clientNotes', 'suspended',
    //     'dateFiled', 'filingType', 'filerName', 'paymentPeriod', 'paymentNoticeDate',
    //     'feeOnly', 'bor', 'status', 'filerNotes', 'firstRequest', 'secondRequest',
    //     'thirdRequest', 'taxAmt'
    // ];


    // const keysNamesFromXlsx: string[] = [
    //     // 'Filing Due Date', 
    //     'Client', 'Invoice Number', 'Insured Name', 'Policy Number',
    //     'Home State', 'Transaction Type', 'Invoice Date', 'Tax Type',
    //     'Policy Eff Date', 'Policy Exp Date', 'Gross Premium', 'Coverage',
    //     'Carrier', 'NAIC', 'Non Admitted Premium', 'Taxable Fees', 'Non Taxable Fees',
    //     'Tax Balance', 'Client Div', 'Date Invoice Added', 'Client Notes', 'Suspended',
    //     'Date Filed', 'Filing Type', 'Filer Name', 'Payment Period', 'Payment Notice Date',
    //     'Fee Only', 'BOR', 'Policy Status', 'Filer Notes', 'First Request', 'Second Request',
    //     'Third Request', 'Tax Amount'
    // ];

    const fetchMainData = (action: string, pageSize: number, pageNumber: number) => {
        // 
        setloading(true);
        console.log(action);
        console.log("refreshdata.");

        const selectedValuesArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        var customerIds = selectedValuesArray.join(',');
        const url = `${config.url.API_URL}/ReconQueue/${action}?userId=${user?.userId}&policyNumber=${PloicyNum.current}
        &stateId=${stateData.current}&effectiveDateStart=${effectiveDateSt.current}&effectiveDateEnd=${effectiveDateEd.current}
        &taxName=${encodeURIComponent(taxData.current)}
        &status=${encodeURIComponent(statusData.current)}
        &insuredName=${insName.current}&customerId=${customerData.current}&customerIds=${customerIds}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

        fetch(

            url,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                setloading(false)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                console.log("reportlistdata", res.data);
                // Do something with the response
                if (res.data.totalCount > 0) {
                    setReportModule(res.data)
                }
                else {
                    setReportModule(initValue)
                }
            }).catch((error) => {
                setloading(false)

                console.log(error)
            });
    }
    
    const status = [
        'Not Started', 'Waiting on Client', 'Ready to File', 'Filed', 'Pending Autofile'
        // 'Missing Documents', 'Pending Documents', 'Flagged','Waiting on Client', 'Ready to File', 'Pending Autofile', 'Pending state review', 'State Flagged', 'Filed', 'IsClosed', 
    ]
    if (user?.userRoles.split(",").includes("Super Admin")) {
        status.push('Removed');
    }

    const debouncedOnFilterChange = debounce((filterModel: GridFilterModel, tabValue: number, pageSize: number, pageNumber: number, field: string, sort?: string) => {

        setloading(true);
        const newFilters: FMFilters = {
          items: filterModel.items.map(item => {
            let value = item.value ? item.value.trim() : ""; // Trim value to remove extra spaces
          
            if (item.columnField === 'grossPremium' || item.columnField === 'taxAmt') {
              value = value.replace('$', '').replace(',','').replace('.00',''); 
              
              console.log("premium",value);
            }
        
            return {
              columnField: item.columnField,
              value: value
            };
          })
        };
    
        // Update query options with the new filters
        setQueryOptions(newFilters);
        console.log("completedvalue", newFilters)
        const getEndpointForTabValue = (tabValue: number) => {
            switch (tabValue) {
              case ReconTabs.Open: return "getReconQueueOpenFilterData";
              case ReconTabs.Closed: return "getReconQueueClosedFilterData";
              default: return null;
            }
        };
        const parseDueDate = (dueDateStr: string): Date => {
            const currentDate = new Date();
            const [month, day] = dueDateStr.split('-').map(Number);
            const year = currentDate.getFullYear();
            return new Date(year, month - 1, day);
        };

        // Check if there are any filters with non-empty values
        const hasValue = newFilters.items.some(item => item.value !== '');
    
    
        const fetchData = (tabValue: number, filters: FMFilters) => {
            const endpoint = getEndpointForTabValue(tabValue);
            console.log("EndPoint", endpoint);
            if (endpoint) {
              fetchDataForFilter(endpoint, tabValue, _pageSize, _currentPage, filters);
            }
        };
    
        if (hasValue) {
          console.log("grosspremium", newFilters);
    
          fetchData(tabValue, newFilters);
        } else {
          setloading(true);
          // Clear filters if no values are present
          const clearedFilters = { items: [] };
          setQueryOptions(clearedFilters);
          //Fetch data without filters
          setPageSize(50);
          fetchData(tabValue, newFilters);
    
        }
    
        // setloading(false); // Stop loading after fetch call
      }, 1200); // 1 second debounce delay
    const fetchDataForFilter = (action: string, newValue: number, pageSize: number, pageNumber: number, newFilters?: FMFilters) => {
        setloading(true);
        const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
        if (effectiveDateSt.current != null && effectiveDateSt.current != '' && effectiveDateSt.current != undefined) {
          let start: Date = new Date(effectiveDateSt.current);
          effectiveDateSt.current = start.toLocaleDateString('en-US', options);
        }
    
        if (effectiveDateEd.current != null && effectiveDateEd.current != '' && effectiveDateEd.current != undefined) {
          let end: Date = new Date(effectiveDateEd.current ?? '');
          effectiveDateEd.current = end.toLocaleDateString('en-US', options);
    
        }
        initFilterModel.customerId = customerData.current;
        initFilterModel.pageNumber = pageNumber;
        initFilterModel.pageSize = pageSize;
        initFilterModel.field = sortModelRef.current[0]?.field ?? '';
        initFilterModel.sort = sortModelRef.current[0]?.sort ?? '';
    
        initFilterModel.agency = newFilters?.items.find(m => m.columnField === "agency")?.value;
        initFilterModel.invoiceNumber = newFilters?.items.find(m => m.columnField === "invoiceNumber")?.value;
        initFilterModel.dueDate = newFilters?.items.find(m => m.columnField === "dueDate")?.value;
        initFilterModel.effectiveDate = newFilters?.items.find(m => m.columnField === "policyEffDate")?.value;
        initFilterModel.grossPremium = newFilters?.items.find(m => m.columnField === "grossPremium")?.value;
        initFilterModel.homeState = newFilters?.items.find(m => m.columnField === "homeState")?.value;
        initFilterModel.insuredName = newFilters?.items.find(m => m.columnField === "insuredName")?.value;
        initFilterModel.invoiceDate = newFilters?.items.find(m => m.columnField === "invDate")?.value;
        initFilterModel.policyExpDate = newFilters?.items.find(m => m.columnField === "policyExpDate")?.value;
        initFilterModel.policyNumber = newFilters?.items.find(m => m.columnField === "policyNumber")?.value;
        initFilterModel.transactionTypeName = newFilters?.items.find(m => m.columnField === "transactionType")?.value;
        initFilterModel.status = newFilters?.items.find(m => m.columnField === "status")?.value;
        initFilterModel.client = newFilters?.items.find(m => m.columnField === "client")?.value;
        initFilterModel.taxAmt = newFilters?.items.find(m => m.columnField === "taxAmt")?.value;
        initFilterModel.dateFiled = newFilters?.items.find(m => m.columnField === "dateFiled")?.value;

        
    
        initFilterModel.policyNumber = isValid(initFilterModel.policyNumber) ? initFilterModel.policyNumber : PloicyNum.current;
        initFilterModel.stateId = isValid(initFilterModel.stateId) ? initFilterModel.stateId : stateData.current;
        initFilterModel.insuredName = isValid(initFilterModel.insuredName) ? initFilterModel.insuredName : insName.current;
    
        fetch(
          config.url.API_URL +
          `/ReconQueue/${action}`,
          {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(initFilterModel)
          }
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Something went wrong");
          })
          .then((responseJson) => {
    
            setloading(false);
            if (responseJson.data.totalCount > 0) {
                setReportModule(responseJson.data);
            } else {

                setReportModule({
                    ...initValue,
                    getData_Resp: [], // Assuming `initValue` has a `data` property that needs to be an empty array
                    totalCount: 0,
                });
            }
          })
          .catch((error) => {
            setloading(false);
          });
    }
    const fetchDataSorting = (pageSize: number, pageNumber: number, field: string, sort?: string) => {
        setloading(true);
        console.log({ pageSize, pageNumber, field, sort });
        console.log("refreshdata.");
        // action = 'getAllTaxTransactionFilingsData';
        const endpoint = getEndpointForTabValue(selectedTab)
        if (!endpoint) return

        const selectedValuesArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        var customerIds = selectedValuesArray.join(',');
        const url = `${config.url.API_URL}/ReconQueue/${endpoint}?userId=${user?.userId}&policyNumber=${PloicyNum.current}
        &stateId=${stateData.current}&effectiveDateStart=${effectiveDateSt.current}&effectiveDateEnd=${effectiveDateEd.current}
        &taxName=${encodeURIComponent(taxData.current)}
        &status=${encodeURIComponent(statusData.current)}
        &insuredName=${insName.current}
        &customerId=${customerData.current}
        &customerIds=${customerIds}
        &pageSize=${pageSize}
        &pageNumber=${pageNumber}
        &field=${field}&sort=${sort}`

        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false);
                console.log("reportlistdata", res.data);

                if (res.data.totalCount > 0) {
                    setReportModule(res.data);
                } else {

                    setReportModule({
                        ...initValue,
                        getData_Resp: [], // Assuming `initValue` has a `data` property that needs to be an empty array
                        totalCount: 0,
                    });
                }
            })
            .catch((error) => {
                setloading(false);
                console.log(error);
            });
    };
    function updateStatus(status: string, selectedRow: any) {
        setloading(true);

        fetch(config.url.API_URL + `/PolicyDashBoard/updateCustomerPolicyStatus?status=${status}&&filerName=${user?.userFirstName + ' ' + user?.userlastName}`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(selectedRow)
        }).then((response) => {
            setloading(false);
            if (response.ok) {
                return response.json();
            }
            throw new Error("Something went wrong");
        }).then((res) => {
            setloading(false);
            if (res !== null && res !== undefined) {
                Swal.fire({
                    title: res.data,
                    text: res.message,
                    iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                    confirmButtonColor: '#02A4E3',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn-radius',
                        cancelButton: 'btn-radius',
                        popup: 'card-radius'
                    }
                }).then((result) => {
                    window.location.reload();
                })
            }
        }).catch((error) => {
            console.log(error);
            setloading(false);
        });
    }
    
    var count = 0;
    function updateAllStatus(status: string, selectedRow: any, notes: string = '', filerName: string = '', firstRequestDate: string = '', secondRequestDate: string = '', thirdRequestDate: string = '', suspendedDate: string = '', noteDate?: string, policySubStatus: string = '', dateDeclarationFiled: string = '') {
        setloading(true);
        if (noteDate === null || noteDate === '' || noteDate === undefined) {
            const newDate = new Date().toISOString().slice(0, 10);
            noteDate = moment(newDate).format("MM/DD/YYYY")          
        }
        if (status === "Filed") {
            var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
            if (count === 0) {

                (async () => {
                    try {
                        const customerPolicyIds: number[] = selectedRows
                                                ?.map(m => m.customerpolicyid)
                                                .filter((id): id is number => id !== undefined) ?? [];

                        // Now you can safely pass it to `getTaxTypeData`
                        const response = await getTaxTypeData('getIsPolicyFilingAndReportByCustPolIds', customerPolicyIds);

                        if (response != null && response.length > 0) {
                            const dueDateNotValid: NewCompletedFilingsModel[] = []; // where due date is not prior 10 days of due date
                            response.forEach((e: getPolicyFilingAndReport) => {
                                if (e.report == true) {
                                    var newData = selectedRows?.find(m => m.customerpolicyid == e.customerPolicyId);
                                    if (!isDueDateWithinRange(newData?.dueDate) && newData !== undefined && newData.customerpolicyid !== undefined) {
                                        dueDateNotValid.push(newData as NewCompletedFilingsModel);
                                    }
                                }
                            });

                            const DueDateNotValid = new Set(dueDateNotValid.map(item => item.customerpolicyid));
                            const filteredSelectedIds: NewCompletedFilingsModel[] | undefined = selectedRows
                                ?.filter((item): item is NewCompletedFilingsModel => 
                                    item.customerpolicyid !== undefined && !DueDateNotValid.has(item.customerpolicyid)
                                );
                            console.log("filteredSelectedIds :", filteredSelectedIds);
                            count++;
                            if (dueDateNotValid.length > 0) {
                                seteditableSelectedRows(filteredSelectedIds)
                                SetCustPolicyNullDueDateData(dueDateNotValid);
                                setOpenPolicyDueDateNullDialog(true);
                            }
                            else {
                                SetCustPolicyNullDueDateData(null);
                                setOpenPolicyDueDateNullDialog(false);
                                seteditableSelectedRows(undefined);
                                updateAllStatus("Filed", filteredSelectedIds, notes, filerName, firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteDate, policySubStatus, dateDeclarationFiled);
                            }
                        }
                        setloading(false);
                        return;
                    } catch (error) {
                        console.error("Error fetching tax type data:", error);
                        return;
                    }
                })();
                return;
            }
        }
        setOpenPolicyDueDateNullDialog(false);

        fetch(config.url.API_URL + `/PolicyDashBoard/updateCustomerPolicyStatus?status=${status}&&notes=${notes}&&filerName=${user?.userFirstName + ' ' + user?.userlastName}&&filerDate=${noteDate}&&firstRequestDate=${firstRequestDate}&&secondRequestDate=${secondRequestDate}&&thirdRequestDate=${thirdRequestDate}&&suspendedDate=${suspendedDate}&&policySubStatus=${policySubStatus}&&dateDeclarationFiled=${dateDeclarationFiled}`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(selectedRow)
        })
            .then((response) => {
                setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((res) => {
                setloading(false);
                if (res !== null && res !== undefined) {
                     Swal.fire({
                        title: res.data,
                        text: res.message,
                        iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                        confirmButtonColor: '#02A4E3',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'btn-radius',
                            cancelButton: 'btn-radius',
                            popup: 'card-radius'
                        }
                    })
                        .then((result) => {

                            setSelectedRows([]);
                            fetchDataForFilter("getOutstandingFilingsFilterData", FMTabs.OutStandingFiling, _pageSize, _currentPage, queryOptions)
                            window.location.reload();
                            // fetchDataMain(
                            //     'getOutstandingFilingsData',
                            //     8,
                            //     _pageSize,
                            //     _currentPage
                            // );
                        })
                }
            })
            .catch((error) => {
                console.log(error);
                setloading(false);
            });
    }
    const getTaxTypeData = async (action: string, customerPolicyIds: number[]) => {
        try {
            //  setloading(true);

            const initValueExport = {
                customerPolicyIds: customerPolicyIds,
                option: ''
            };

            const response = await fetch(
                config.url.API_URL + `/PolicyDashBoard/${action}`,
                {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }),
                    body: JSON.stringify(initValueExport)
                }
            );

            if (!response.ok) {
                throw new Error("Something went wrong");
            }

            const res = await response.json();

            if (res.data !== null) {
                //  setloading(false);
                return res.data;
            } else {
                // setloading(false);
                throw new Error("Response data is null");
            }
        } catch (error) {
            // setloading(false);
            throw error;
        }
    };
    const handlePaid = () => {
        console.log({ selectedRows })
        const status: string = 'Paid'
        Swal.fire({
            title: 'Confirmation!',
            text: "Do you want to update customer policy's status as " + status + "?",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setloading(true);
                if (status !== undefined && status !== '' && status !== null) {
                    updateStatus('Paid', selectedRows);
                }
            }
        });

        setloading(false);
    }

    const onExportXlsx = (values: ReconciliationTransactionReportModel[]) => {
        setloading(true);
        if (values?.length === 0) {
            setloading(false);
            return;
        }
        const fieldsToExport: (keyof ReconciliationTransactionReportModel)[] = [
            'invoiceNumber', 'insuredName', 'policyNumber',
            'homeState', 'taxType', 'transactionType', 'invDate',
            'policyEffDate', 'policyExpDate', 'grossPremium', 'taxAmt', 'client', 'clientDiv','dateFiled'
        ];
        const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

        const formattedValues = values.map(item => {
            // Helper function to clean and format the value
            const cleanAndFormatValue = (value: string | null | undefined): string => {
                if (value == null || value === '') return ""; // Handle empty, null, or undefined values
            
                // Trim any spaces and remove the dollar sign and commas
                let cleanedValue = value.replace(/[,\$]/g, "").trim();
            
                // If the value is in parentheses, treat it as negative
                if (cleanedValue.startsWith("(") && cleanedValue.endsWith(")")) {
                    cleanedValue = "-" + cleanedValue.slice(1, -1);  // Remove parentheses and add negative sign
                }
            
                // Convert to a number and format if valid
                const numericValue = parseFloat(cleanedValue);
                return isNaN(numericValue) ? value : `${formatPrice(numericValue.toString())}`;
            };
        
            // Apply formatting for taxAmt and grossPremium
            item.taxAmt = cleanAndFormatValue(item.taxAmt);
            item.grossPremium = cleanAndFormatValue(item.grossPremium);
        
            // Format dates if they are not null or empty
            const invDate = item.invDate && item.invDate !== '1900-01-01T00:00:00' ? new Date(item.invDate) : "";
            const policyEffDate = item.policyEffDate && item.policyEffDate !== '1900-01-01T00:00:00' ? new Date(item.policyEffDate) : "";
            const policyExpDate = item.policyExpDate && item.policyExpDate !== '1900-01-01T00:00:00' ? new Date(item.policyExpDate) : "";
            const dateFiled = item.dateFiled && item.dateFiled !== '1900-01-01T00:00:00' ? new Date(item.dateFiled) : "";
        
            const update: (string | number | Date | undefined)[] = fieldsToExport.map((key: keyof ReconciliationTransactionReportModel) => {
                if (key === 'invDate') return invDate;
                if (key === 'policyEffDate') return policyEffDate;
                if (key === 'policyExpDate') return policyExpDate;
                if (key === 'dateFiled') return dateFiled;
                return item[key];
            });
        
            return update;
        });
        
        
        const headers = fieldsToExport.map(field => capitalizeFirstLetter(field));

        const updates = [
            headers,
            ...formattedValues
        ];

        writeXlsxFile(updates);
        setloading(false);
    };
    function convertDateFormat(dateStr: string | undefined): Date | undefined {
        if (!dateStr || isNaN(Date.parse(dateStr))) {
            return undefined;
        }
        return moment.utc(dateStr).startOf('day').toDate();
    }

    const handleExportXlsxNew = async (val: ReconciliationTransactionReportModel[]) => {
        try {
            setloading(true);

            console.log("selecteddata", selectedRows);
            // const action = "getAllTaxTransactionFilingsData";
            const action = getEndpointForTabValue(selectedTab)
            if (!action) return
            // debugger

            const response = await fetchExportData(action, selectedOptions, ReportModule.totalCount, 1, selectedRows);
            let values = response
            if (val && val?.length > 0) {
                values = val
            }
            if (values?.length <= 0) {
                alert('Please upload a xlsx file')
                return
            }
            onExportXlsx(values);
            setSelectedRows([]);
            fetchMainData(action, _pageSize, _currentPage);
            setloading(false);
        } catch (error) {
            setloading(false);
            console.error("Error occurred:", error);
        }
    }

    async function fetchExportData(action: string, customers: string | string[], pageSize: number, pageNumber: number, selectedRows: any) {

        setloading(true);
        var MultiplePolicyIds: string = selectedRows?.map((x: { customerpolicyid: string }) => x.customerpolicyid).join(",") ?? "";

        const response = await fetch(
            `${config.url.API_URL}/ReconQueue/${action}Export`,
            {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
                body: JSON.stringify({
                    userId: user?.userId,
                    policyNumber: PloicyNum.current,
                    stateId: stateData.current,
                    effectiveDateStart: effectiveDateSt.current,
                    effectiveDateEnd: effectiveDateEd.current,
                    taxName: taxData.current,
                    status: statusData.current,
                    insuredName: insName.current,
                    customerId: customerData.current,
                    customerIds: MultiplePolicyIds,
                    pageSize: pageSize,
                    pageNumber: pageNumber
                })
            }
        )
            .then((response) => {
                setloading(false)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                setloading(false)
                if (res.data.totalCount > 0) {

                    if (pageSize <= 100) {
                        setReportModule(res.data)
                    }


                    return res.data.getData_Resp;
                }

            }).catch((error) => {
                setloading(false)
            });
        return response;
    }

    const [TaxandFees, setTaxandFees] = useState<string[]>([""]);

    const fetchTaxFeeName = () => {
        fetch(config.url.API_URL + `/PolicyFilingType/GetAllTaxandFeeName`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                if (responseJson.data != null) {
                    setTaxandFees(responseJson.data);
                }

                console.log("TaxandFees", responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchTaxFeeName();
    }, []);

    const FMSelectTaxAndFeesCustom: React.FC<SelectProps> = ({
        variant,
        ...props
    }) => {
        const handleTaxChange = (event: SelectChangeEvent<string>) => {
            const selectedTax = event.target.value;
            setloading(true); // Correctly use setLoading
            setTax(selectedTax);
            taxData.current = selectedTax;
            console.log('taxcurrentdata', taxData.current);
            setloading(false);
        };

        return (
            <div>
                <FormControl fullWidth>
                    <Select
                        onChange={handleTaxChange}
                        value={tax}
                        id="test-select-label"
                        autoWidth
                        size="small"
                        label={null}
                        className="border-round custom-menu-item"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                    >
                        <MenuItem key={""} value={""} className="custom-menu-item">
                            Tax Type
                        </MenuItem>
                        {TaxandFees?.map((taxdata, key) => (
                            <MenuItem
                                key={key}
                                className="custom-menu-item"
                                value={taxdata}
                            >
                                {taxdata}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };

    const statusList = [
        'Not Started',
        'Waiting on Client',
        'Ready to File',
        'Filed',
        'Pending Autofile',
        // 'Closed',
        'Filed - Binder'
    ];
    const isRowSelected = selectedRows !== null && selectedRows?.length > 0

    const FMSelectStatus: React.FC<SelectProps> = ({
        variant,
        ...props
    }) => {

        const handleStatusChange = (event: SelectChangeEvent<string>) => {
            const selectedStatus = event.target.value;
            setloading(true);
            setstatus(selectedStatus);
            statusData.current = selectedStatus; // Update the ref
            console.log('Current Status Data:', statusData.current);
            setloading(false);
        };

        return (
            <div>
                <FormControl fullWidth>
                    <Select
                        onChange={handleStatusChange}
                        value={statuses} // Ensure this matches the state
                        id="status-select"
                        autoWidth
                        size="small"
                        label={null}
                        className="border-round custom-menu-item"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                    >
                        <MenuItem key={""} value={""} className="custom-menu-item">
                            Status
                        </MenuItem>
                        {statusList.map((item, key) => (
                            <MenuItem
                                key={key}
                                className="custom-menu-item"
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };

    const initialUserdata = { partnersId: 0, customerName: "" };
    const [Customer, setCustomer] = useState([initialUserdata]);
    const fetchCustomer = () => {
        fetch(config.url.API_URL + "/Partners/getPartners", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                setCustomer(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const [selectedOptions, setSelectedOption] = React.useState<string[] | string>([]);

    const FMSelectCustomCustomer: React.FC<SelectProps> = ({
        variant,
        ...props
    }) => {
        const handleChange = (event: SelectChangeEvent) => {
            setloading(true);
            setCustomerId(Number(event.target.value));
            customerData.current = Number(event.target.value);
            setloading(false);
        };

        return (
            <div>
                <FormControl fullWidth>
                    {/* <InputLabel id="test-select-label"></InputLabel> */}
                    <Select
                        onChange={handleChange}
                        value={customerId.toString()}
                        id="test-select-label"
                        autoWidth
                        size="small"
                        label={null}
                        className="border-round custom-menu-item"
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                    >
                        <MenuItem key={0} value={0} className="custom-menu-item">
                            Client
                        </MenuItem>
                        {Customer?.map((item, key) => (
                            <MenuItem
                                key={key}
                                className="custom-menu-item"
                                value={item.partnersId}
                            >
                                {item.customerName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };

    function RunSearch() {

        const action = getEndpointForTabValue(selectedTab)
        if (!action) return
        fetchMainData(action, _pageSize, _currentPage);

    }

    const initialStatedata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialStatedata]);

    const [openPaidDialog, setOpenPaidDialog] = useState(false);
    const fetchStateData = () => {
        fetch(config.url.API_URL + "/States/getStates", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response

                setStates(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const FMSelectStateCustom: React.FC<SelectProps> = ({ variant, ...props }) => {
        const handleChangestate = (event: SelectChangeEvent) => {
            setloading(true);

            setstateId(Number(event.target.value));
            stateData.current = Number(event.target.value);
            setloading(false);
        };
        return (

            <FormControl fullWidth>
                <Select
                    onChange={handleChangestate}
                    value={stateId.toString()}
                    size="small"
                    id="test-select-label"
                    // autoWidth
                    label={null}
                    className="border-round custom-menu-item"
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                >
                    <MenuItem value={"0"} className="custom-menu-item">Home State</MenuItem>
                    {States?.map((state, key) => (
                        <MenuItem
                            key={key}
                            value={state.stateId}
                            className="custom-menu-item"
                        >
                            {state.stateName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    function ClearSearch() {
        setloading(true);

        setstateId(0);
        setCustomerId(0);
        setstatus("");
        setTax("");

        stateData.current = 0;
        customerData.current = 0;
        taxData.current = "";
        statusData.current = "";
        setloading(false);

        const action = getEndpointForTabValue(selectedTab)
        if (!action) return
        fetchMainData(action, _pageSize, _currentPage);
    }

    const handleClosePaidDialog = () => {
        setOpenPaidDialog(false);
    };
    interface newProp {
        handleClose: () => void;
        open: boolean;
    }

    interface StatusUpdateProp {
        // selectedRows: ReconciliationTransactionReportModel[] | null;
        paymentPeriod?: string;
        isPaid: boolean;
    }

    const ShowPaymentPeriod = ({ open, handleClose }: newProp) => {

        const initValuePaid: StatusUpdateProp = {
            isPaid: true,
            paymentPeriod: '',
        }

        return (
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
                <DialogTitle className='card-title'>Payment Period</DialogTitle>
                <React.Fragment>
                    <Formik
                        initialValues={initValuePaid}
                        onSubmit={(data, setError) => {
                            updateStatusChange(data)
                        }}
                    >
                        {({ values, isSubmitting, setErrors }) => (
                            <Form className='main-form'>

                                <DialogContent>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%', minWidth: '500px' }}>
                                            <FMTextField
                                                name="paymentPeriod"
                                                label={null}
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ marginTop: 2 }}
                                                multiline
                                                required
                                            />
                                        </FormControl>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <button className='btn-2 btn d-flex btn-primary search-btn' style={{ minWidth: '125px' }} type='button' onClick={handleClose}>Cancel</button>
                                    <button className='btn btn-primary active-btn-blue' type='submit' disabled={isSubmitting}>Submit</button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>

            </Dialog>
        );
    };
    function checkSelectedRowsForNY(): boolean {
        if (selectedRows?.find(x => x.homeState === 'NY')) {
            if (selectedRows.find(x => x.homeState !== 'NY')) {
                Swal.fire({
                    title: 'Warning!',
                    text: "Please select filings either specifically for New York or those that exclude New York, as there is a special rule for changing the status in New York.",
                    iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                    // showCancelButton: true,
                    confirmButtonColor: '#02A4E3',
                    // cancelButtonColor: '#d33',
                    // confirmButtonText: 'Proceed',
                    customClass: {
                        confirmButton: 'btn-radius',
                        // cancelButton: 'btn-radius',
                        popup: 'card-radius'
                    }
                })
                    .then((result) => {

                    });
                return false;
            }


        }
        return true;
    }
    const getCustomerPolicyDetails = async (customerPolicyId: number): Promise<string> => {
        try {
          setloading(true);
          const initCustValue = {
            customerPolicyId: customerPolicyId
          };
          var action = 'getPolicySubStatusByCustPolId';
          const response = await fetch(
            config.url.API_URL + `/PolicyDashBoard/${action}`,
            {
              method: "POST",
              headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }),
              body: JSON.stringify(initCustValue)
            }
          );
    
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          const res = await response.json();
          if (res.data !== null) {
            setloading(false);
            return res.data;
          } else {
            setloading(false);
            throw new Error("Response data is null");
          }
        } catch (error) {
          setloading(false);
          throw error;
        }
      };

    function handleStatus(status: string) {
        if (!checkSelectedRowsForNY()) {
            return;
        }
        setCurrentPolicySubStatus('');
        const isNewYorkFiling = selectedRows && selectedRows?.length === 1 && selectedRows[0].homeState === 'NY' && (selectedRows[0].transactionTypeName === 'New Business' || selectedRows[0].transactionTypeName === 'Renewal Business')
        console.log("status------------", status);
        // debugger;
        if (status === 'Waiting on Client' || (status === 'Filed' && isNewYorkFiling)) {
            (async () => {
                const customerPolicyId = selectedRows && selectedRows[0]?.customerpolicyid !== undefined
                    ? selectedRows[0].customerpolicyid
                    : 0;
                const response = await getCustomerPolicyDetails(customerPolicyId);
                // debugger;
                setCurrentPolicySubStatus(response);
                handleOpenNotesDialog(status);

            })();
        }
        else if (selectedRows?.find(m => m.homeState === "NY") && selectedRows.length !== 1 && status === "Filed") {
            Swal.fire({
                title: "",
                text: "Please select one filing for 'New York' state",
                icon: 'warning',
                confirmButtonText: "OK",
            });
            return;
        }
        else {
            Swal.fire({
                title: 'Confirmation!',
                text: "Do you want to update customer policy's status as " + status + "?",
                iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                showCancelButton: true,
                confirmButtonColor: '#02A4E3',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Proceed',
                customClass: {
                    confirmButton: 'btn-radius',
                    cancelButton: 'btn-radius',
                    popup: 'card-radius'
                }
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        setloading(true);
                        if (status !== undefined && status !== '' && status !== null) {
                            setCurrentPolicySubStatus('');
                            updateStatus(status, selectedRows);

                        }
                    }
                });

            setloading(false);
        }
    }

    const updateStatusChange = ({isPaid, paymentPeriod}: StatusUpdateProp) => {
        fetch(
            config.url.API_URL + `/ReconQueue/updateReconPaidStatus`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                body: JSON.stringify({
                    selectedRows: selectedRows,
                    isPaid,
                    paymentPeriod,
                })
            }
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        }).then((res) => {
            if (res.resp === 1) {
                Swal.fire({
                    title: res.message,
                    text: "",
                    icon: "warning",
                    confirmButtonText: "OK",
                });
                console.log("response", res);
            } else if (res.resp === 0) {

                Swal.fire({
                    title: "Data Successfully saved",
                    text: "",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setSelectedRows([]);
                handleClosePaidDialog();
                const action = getEndpointForTabValue(selectedTab)
                if (!action) return
                fetchMainData(action, _pageSize, _currentPage);

            } else {
                Swal.fire({
                    title: "Unexpected response",
                    text: "The response code was not recognized.",
                    icon: "warning",
                    confirmButtonText: "OK",
                });

                console.log("response", res);
            }
        }).catch((error) => {
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
            });
            console.error('Error:', error);
        });
    }
    const handleStatusChange = () => {
        if (selectedTab === ReconTabs.Open) {
            // onClick 'Paid' button
            setOpenPaidDialog(true);
            return
        }

        // onClick 'Revert' button
        Swal.fire({
            title: 'Confirmation!',
            text: "Do you want to revert?",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setloading(true);
                updateStatusChange({
                    isPaid: false,
                    paymentPeriod: '',
                })
            }
        });
    }

    const handleExport = (option: string) => {
        handleExportXlsxNew([])
    };
    const getEndpointForTabValue = (tabValue: number) => {
        switch (tabValue) {
            case ReconTabs.Open: return "getReconQueueOpen";
            case ReconTabs.Closed: return "getReconQueueClosed";
            default: return null;
        }
    };


    const [selectedTab, setSelectedTab] = useState<number>(1);

    // useEffect(() => {
    // const action = getEndpointForTabValue(selectedTab)
    // if (!action) return
    // console.log('useEffect', { action, selectedTab, _pageSize, _currentPage });
    // fetchMainData(action, _pageSize, _currentPage);
    // fetchCustomer();
    // fetchStateData();
    // }, [])

    const params = useParams()
    const handleTabChange = (event: React.SyntheticEvent | undefined, tabValue: number) => {

        setloading(true);
        setSelectedTab(tabValue);

        if (params.LastTabId != String(tabValue)) {
            window.location.href = "/ReconciliationQueue/" + tabValue;
        } else {
            setloading(false);
        }
    };
    useEffect(() => {
        let oldTabValue =
            params.LastTabId === undefined ||
                params.LastTabId === "" ||
                params.LastTabId === null
                ? 1
                : Number(params.LastTabId);
        console.log("useeffect triggered and OldTabValue", oldTabValue);
        setSelectedTab(oldTabValue);
        handleTabChange(undefined, oldTabValue);

        setReportModule(initValue);
        fetchStateData();
        fetchCustomer();
        if (oldTabValue === ReconTabs.Open) {
            const action = getEndpointForTabValue(ReconTabs.Open)
            if (!action) return
            fetchMainData(action, _pageSize, _currentPage);
            return;
        } else {
            const action = getEndpointForTabValue(ReconTabs.Closed)
            if (!action) return
            fetchMainData(action, _pageSize, _currentPage);
            return;
        }

    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="card-title">
                    <span className="d-flex">Reconciliation Queue</span>
                </div>
            </div>
            <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
            </div>
            <Box sx={{ width: "100%" }}>
                <div className="col-lg-12    ">
                    <div
                        className=" d-flex  "
                        style={{ marginBottom: "28px" }} role="group"
                        aria-label="Basic example"
                    >
                        <React.Fragment>
                            <div className="col-6  text-center ">
                                <button type="button"
                                    className={`btn-tab btn ${selectedTab === ReconTabs.Open ? "btn-Selected" : "btn-primary1"}`}
                                    {...a11yProps(ReconTabs.Open)}
                                    onClick={() => handleTabChange(undefined, ReconTabs.Open)}
                                >
                                    Open Payables
                                </button>
                            </div>
                            <div className="col-6  text-center ">
                                <button type="button"
                                    className={`btn-tab btn ${selectedTab === ReconTabs.Closed ? "btn-Selected" : "btn-primary1"}`}
                                    {...a11yProps(ReconTabs.Closed)}
                                    onClick={() => handleTabChange(undefined, ReconTabs.Closed)}
                                >
                                    Closed Payables
                                </button>
                            </div>
                        </React.Fragment>
                    </div>
                </div>

                <Box sx={{ width: "100%" }}>
                    <Box>
                        <Grid
                            container
                            spacing={2}
                            className="filter-grid"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xl={2} lg={4} sm={6} xs={12}>
                                {(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC State Reporter")) ? (
                                    <FMSelectCustomCustomer name="customer" />
                                ) : ""}
                            </Grid>
                            <Grid item xl={2} lg={4} sm={6} xs={12}>
                                <FMSelectStateCustom name="state" />
                            </Grid>
                            <Grid item xl={2} lg={4} sm={6} xs={12}>
                                <FMSelectTaxAndFeesCustom name="taxName" />
                            </Grid>
                            <Grid item xl={2} lg={4} sm={6} xs={12}>
                                <FMSelectStatus />
                            </Grid>
                            <Grid item xl={0.7} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                RunSearch();
                                            }}
                                            className="active-btn1-blue-tabs"
                                        >
                                            Search
                                        </button>
                                    </div>
                                </FormControl>
                            </Grid>

                            <Grid item xl={0.7} sm={12} xs={12} alignItems="center">
                                <FormControl fullWidth>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                ClearSearch();
                                            }}
                                            className="btn-clear-refresh btn"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </FormControl>
                            </Grid>

                            <Grid item xl={0.7} sm={12} xs={12} alignItems="center">
                                <FormControl fullWidth>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                            className="btn-clear-refresh btn"
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </Box>

            <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="card-title">

                </div>
                <div className="d-flex align-items-center mt-2" >

                    {(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC State Reporter")) && (selectedRows !== null && selectedRows?.length > 0) ?
                        <button
                            className=" ms-2 active-btn-red "
                            onClick={() => handleStatusChange()}

                            title="Paid" >
                            {selectedTab === ReconTabs.Open ? 'Paid' : 'Revert'}
                        </button>
                        : ""
                    }
                    {selectedRows?.length !== 0 && selectedRows?.length !== undefined ?

                        <div className="dropdowm-class ms-2" style={{ zIndex: "9" }} >
                            <MenuDropdown
                                menuTitle={'Status'}
                                items={status}
                                onItemClick={handleStatus} />
                        </div>
                        : ''}

                    <button
                        className=" ms-2  active-btn-blue-export-12  "
                        onClick={() => { handleExport('') }}

                        title="Export" >
                        Export
                    </button>

                </div>
            </div>

            <TabPanel value={selectedTab} index={ReconTabs.Open}>
                <ReconciliationOpenList
                    ReportModuleCL={ReportModule}
                    fetchDataMain={fetchDataSorting}
                    selectedButton={selectedTab}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    setCurrentPage={setCurrentPage}
                    _currentPage={_currentPage}
                    setPageSize={setPageSize}
                    _pageSize={_pageSize}
                    debouncedOnFilterChange={debouncedOnFilterChange}
                    queryOptions={queryOptions}
                    fetchDataForFilter={fetchDataForFilter}
                />
            </TabPanel>
            <TabPanel value={selectedTab} index={ReconTabs.Closed}>
                <ReconciliationClosedList
                    ReportModuleCL={ReportModule}
                    fetchDataMain={fetchDataSorting}
                    selectedButton={selectedTab}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    setCurrentPage={setCurrentPage}
                    _currentPage={_currentPage}
                    setPageSize={setPageSize}
                    _pageSize={_pageSize}
                    debouncedOnFilterChange={debouncedOnFilterChange}
                    queryOptions={queryOptions}
                    fetchDataForFilter={fetchDataForFilter}
                />
            </TabPanel>

            {openNotesDialog && <FilerNotes
                open={!!openNotesDialog}
                handleClose={handleCloseNotesDialog}
                handleSubmit={handleSubmitNotes}
                selectedRows={filteredSelectedRows}
                currentStatus={openNotesDialog}
                currentPolicySubStatus={currentPolicySubStatus}
            />}
            <ShowPaymentPeriod handleClose={handleClosePaidDialog} open={openPaidDialog} />
            
        </LocalizationProvider>
    );

};



